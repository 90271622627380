import React from 'react';

import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

const Contact = () => {
  return(
    <section id="contact">
      <div>
        <h1>KONTAKT</h1>
        <ul>
          <li>ADRESSE: Kleiststraße 2,<br/> 65187 Wiesbaden</li>
          <li>TELEFON: <a href="tel:0611 16868335">0611 16868335</a></li>
          <li>EMAIL: <a href="jakobian@britmania.de">jakobian@britmania.de</a></li>
          <div id="social">
            <li><a href="https://www.facebook.com/Drei-Lilien-Cafe-im-Dichterviertel-407286716791085/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare className="social-logo"/>Facebook</a></li>
          </div>
        </ul>
      </div>
      <div>
        <h1>ÖFFNUNGSZEITEN</h1>
        <ul>
          <li>Do-Sa, 12:00Uhr - 18:00Uhr</li>
          <li>So, 10:00Uhr - 17:00Uhr</li>
        </ul>
        <p>Aktuelle Informationen finden Sie auf unserer Facebook Seite oder auf Google</p>
      </div>
    </section>
  )
}

export default Contact
