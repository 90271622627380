import React from 'react';
import Contact from './Contact';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FaAngleUp } from 'react-icons/fa';
import Frühstück from '../assets/breakfast.jpeg';
import Speisen from '../assets/speisen.jpeg';
import Heißgetränke from '../assets/kaffee.jpg';
import Softdrinks from '../assets/softdrinks.jpg';
import Alkoholisches from '../assets/alkohol.jpg';

const Menu = () => {
  return(
    <main id="top">
      <section id="image-menu">
        <img alt=""
          style={{backgroundImage: `url(${Frühstück})`}}
        />
        <p id="overlay-text-menu" className="animated fadeIn slow">Frühstückskarte</p>
      </section>
      <section id="menu">
        <div className="grid-container">
                <div className="grid-itemlong">
                  <span>Wiesbaden</span><br/>Brötchen, Käse, Wurst, Butter, Marmelade
                  </div>
          <div className="grid-item"></div>
                  {/* <div className="grid-item">5,50€</div> */}
                <div className="grid-itemlong">
                  <span>Wiesbaden de lux</span><br/>Brötchen, Käse, Wurst, Butter, Marmelade, zusätzlich Lachs, Ei, ein Glas Sekt
                  </div>
          <div className="grid-item"></div>
                  {/* <div className="grid-item">10,50€</div> */}
          <div className="grid-itemlong">
            <span>Paris</span><br/>Cafe au lait mit Croissant, Marmelade & Butter
            </div>
          <div className="grid-item"></div>
            {/* <div className="grid-item">5,50€</div> */}
            <div className="grid-itemlong">
              <span>München</span><br/>2 Weißwürste mit süßem Senf und Brezn
              </div>
          <div className="grid-item"></div>
              {/* <div className="grid-item">5,50€</div> */}
              <div className="grid-itemlong">
                <span>New York</span><br/>Pancakes, Butter, Maple Syrup
                </div>
          <div className="grid-item"></div>
                {/* <div className="grid-item">6,50€</div> */}
                <div className="grid-itemlong">
                  <span>Rührei</span><br/>
                  </div>
          <div className="grid-item"></div>
                  {/* <div className="grid-item">5,50€</div> */}
                <div className="grid-itemlong">
                  <span>Rührei mit Speck</span><br/>
                  </div>
          <div className="grid-item"></div>
                  {/* <div className="grid-item">6,50€</div> */}
                <div className="grid-itemlong">
                  <span>Fitness</span><br/>Porridge mit Joghurt, Früchten, Nüssen und Kernen
                  </div>
          <div className="grid-item"></div>
                  {/* <div className="grid-item">6,50€</div> */}
                <div className="grid-itemlong">
                  <span>Orient</span><br/>Shakshuka
                  </div>
          <div className="grid-item"></div>
                  {/* <div className="grid-item">6,50€</div> */}
                </div>
              </section>
              <section id="image-menu">
                <img alt=""
                  style={{backgroundImage: `url(${Speisen})`}}
                />
                <p id="overlay-text-menu" className="animated fadeIn slow">Speisekarte</p>
              </section>
              <section id="menu">
                <div className="grid-container">
                  <div className="grid-itemlong">
                    <span>Club-Sandwich</span><br/>Putenbrust, Bacon, Avocado, Chutney, Mayo
                    </div>
          <div className="grid-item"></div>
                    {/* <div className="grid-item">6,50€</div> */}
                    <div className="grid-itemlong">
                      <span>Grill-Sandwich Käse</span><br/>mit Salatbouqet
                      </div>
          <div className="grid-item"></div>
                      {/* <div className="grid-item">5,00€</div> */}
                      <div className="grid-itemlong">
                        <span>Grill-Sandwich Käse/Schinken</span><br/>mit Salatbouqet
                        </div>
          <div className="grid-item"></div>
                        {/* <div className="grid-item">5,50€</div> */}
                        <div className="grid-itemlong">
                          <span>Vorspeise-Teller</span><br/>diverse Leckereien
                          </div>
          <div className="grid-item"></div>
                          {/* <div className="grid-item">ab 4,50€</div> */}
                          <div className="grid-itemlong">
                            <span>Tagessuppe</span><br/>"Sometimes-Soup", sometimes we have it, sometimes we don't
                            </div>
          <div className="grid-item"></div>
                            {/* <div className="grid-item">ab 5,50€</div> */}
                            <div className="grid-itemlong">
                              <span>Salate</span><br/>Blatt-/Kräutersalate mit Grillgemüse, Niceoise etc.
                              </div>
          <div className="grid-item"></div>
                              {/* <div className="grid-item">ab 4,50€</div> */}
                              {/* <div className="grid-itemlong"> */}
                                {/* <span>Waffeln</span><br/>mit Topping 1€ extra */}      
                                {/* </div> */}
                                {/* <div className="grid-item">ab 3,50€</div> */}
                              </div>
                            </section>
                            <section id="image-menu">
                              <img alt=""
                                style={{backgroundImage: `url(${Heißgetränke})`, backgroundPosition: 'left'}}
                              />
                              <p id="overlay-text-menu" className="animated fadeIn slow">Heißgetränke</p>
                            </section>
                            <section id="menu">
                              <div className="grid-container">
                                <div className="grid-itemlong"><span>Kaffee</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€</div> */}
                                <div className="grid-itemlong"><span>Espresso</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,00€</div> */}
                                <div className="grid-itemlong"><span>Espresso Macchiato</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€</div> */}
                                <div className="grid-itemlong"><span>Espresso Doppio</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Cappuccino</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Latte Macchiato</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,80€</div> */}
                                <div className="grid-itemlong"><span>Milchkaffee</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,80€</div> */}
                                <div className="grid-itemlong"><span>Cafe au lait</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Kakao</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Chai-Latte</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Tee</span><br/>Assam, Afternoon, Breakfast, Darjeeling, Earl Grey, Lady Frey, grüner Tee, Schietwetter-Tee, Roibusch, Kräuter, Pefferminze</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                              </div>
                            </section>
                            <section id="image-menu">
                              <img alt=""
                                style={{backgroundImage: `url(${Softdrinks})`}}
                              />
                              <p id="overlay-text-menu" className="animated fadeIn slow">Softdrinks</p>
                            </section>
                            <section id="menu">
                              <div className="grid-container">
                                <div className="grid-itemlong"><span>Kombucha</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                                <div className="grid-itemlong"><span>Wasser / still</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                                <div className="grid-itemlong"><span>Cola / Cola light / Zero</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                                <div className="grid-itemlong"><span>Ginger Ale</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                                <div className="grid-itemlong"><span>Bitter Lemon</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                                <div className="grid-itemlong"><span>Homemade Lemonade</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                                <div className="grid-itemlong"><span>Säfte/Schorlen</span> 0,2l/0,33l</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€/3,50€</div> */}
                              </div>
                            </section>
                            <section id="image-menu">
                              <img alt=""
                                style={{backgroundImage: `url(${Alkoholisches})`}}
                              />
                              <p id="overlay-text-menu" className="animated fadeIn slow">Alkoholisches</p>
                            </section>
                            <section id="menu">
                              <h2>Biere</h2>
                              <div className="grid-container">
                                <div className="grid-itemlong"><span>Helles</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Hefeweizen</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,50€</div> */}
                                <div className="grid-itemlong"><span>Alkoholfreies Hefeweizen</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,50€</div> */}
                                <div className="grid-itemlong"><span>Stout</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,00€</div> */}
                                <div className="grid-itemlong"><span>Ale</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,00€</div> */}
                                <div className="grid-itemlong"><span>Jeffe Ruby</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,00€</div> */}
                              </div>
                              <h2>Weine / Schorlen</h2>
                              <div className="grid-container">
                                <div className="grid-itemlong"><span>Weißwein</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,50€</div> */}
                                <div className="grid-itemlong"><span>Rotwein</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,50€</div> */}
                                <div className="grid-itemlong"><span>Cidre</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,00€</div> */}
                                <div className="grid-itemlong"><span>Sekt Perle</span> Sekt, Kugel Zitroneneis</div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">5,50€</div> */}
                              </div>
                              <h2>Digestives / Longdrinks</h2>
                              <div className="grid-container">
                                <div className="grid-itemlong"><span>Eierlikör</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">2,50€</div> */}
                                <div className="grid-itemlong"><span>Willi</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">3,50€</div> */}
                                <div className="grid-itemlong"><span>Cognac</span></div>
          <div className="grid-item"></div>
                                {/* <div className="grid-item">4,50€</div> */}
                              </div>
                            </section>
                            <Contact/>
                            <AnchorLink href='#top'><FaAngleUp className="up-arrow"/></AnchorLink>
                          </main>
  )
}

export default Menu
