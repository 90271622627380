import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.scss';
import './assets/animate.css';

import Nav from './components/Nav';
import Home from './components/Home';
import Menu from './components/Menu';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class App extends Component {
  render() {
    return (
     <Router>
      <div className="App">
        <Nav />
        <Switch>
           <Route path="/" exact component={Home} />
           <Route path="/menu/" component={Menu} />
        </Switch>
      </div>
     </Router>
    );
  }
}

export default App;
