import React from 'react';
import Contact from './Contact';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import image  from '../assets/innen.jpg';
import image2 from '../assets/sessel.jpg';
import image3 from '../assets/blumen.jpg';
import kuchen from '../assets/kuchen.jpg';
import theke from '../assets/theke.jpg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleUp } from 'react-icons/fa';

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  return(
    <main id="top">
      <section id="image">
        <img id="home" alt=""
          style={{backgroundImage: `url(${image})`}}
        />
        <p id="overlay-text" className="animated fadeIn slower">WILLKOMMEN IM DREI LILIEN CAFE</p>
 {/* <p id="overlay-text2">Liebe Freunde der entspannten Genusskultur, das Drei Lilien Café hat vom 1.8.23 bis einschließlich 23.8.23 Betriebsferien. Wir wünschen Allen eine schöne Ferienzeit und freuen uns auf ein Wiedersehen. Ihr Drei-Lilien-Café-Team</p> */}
      </section>
      <section id="about">
        <div id="overlay-about">
          <h1>Unser Angebot für Sie</h1>
          <ul>Täglich bieten wir Ihnen:<br/><br/>
          <li>Frühstück</li>
          <li>frische Backwaren aus eigener Produktion</li>
          <li>Tagessuppen</li>
          <li>Sandwiches u.v.m.</li>
          <br/> Dazu bieten wir Ihnen eine erlesene Kaffee und Tee Auswahl, nationale und internationale Softdrinks, Biere, Weine...
          </ul>
        </div>
      </section>
      <section id="slider">
        <Slider {...settings}>
          <div>
            <img alt=""
              style={{backgroundImage: `url(${image2})`}}
            />
          </div>
          <div>
            <img alt=""
              style={{backgroundImage: `url(${image3})`}}
            />
          </div>
          <div>
            <img alt=""
              style={{backgroundImage: `url(${kuchen})`}}
            />
          </div>
          <div>
            <img alt=""
              style={{backgroundImage: `url(${theke})`}}
            />
          </div>
        </Slider>
      </section>
      <Contact/>
      <AnchorLink href='#top'><FaAngleUp className="up-arrow"/></AnchorLink>
    </main>
  )
}

export default Home
